'use client'

import ErrorIcon from '@/components/icons/ErrorIcon'

import styles from './styles.module.css'

const LoginError = () => (
  <div className={styles.error}>
    <ErrorIcon />

    <h1>There was an unexpected error</h1>
  </div>
)

export default LoginError
